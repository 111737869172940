import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../../../../shared/components/general/no-results/no-results.js';
import '../../../../shared/components/activities/activity-list/activity-list.js';
import '../../../../shared/components/activities/activity-filter/activity-filter.js';

import { animate, AnimateController } from '@lit-labs/motion';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';

import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import ActivitiesHelper from '../../../../../shared/helpers/activities.js';
import Activity from '../../../../../shared/models/activity/activity.js';
import ActivityFilter from '../../../../shared/models/activity-filter/activity-filter.js';
import { CAREER_ITEM_TYPES } from '../../../../../shared/models/schema/job.js';
import CareerStream from '../../../../shared/models/career-stream/career-stream.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { mapify } from '../../../../../shared/methods.js';

export default class ViewActivitiesDashboard extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      _refreshing: { type: Boolean },
      _streams: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      selectStyles,
      heading2Styles,
      css`
        :host {
          display: block;
        }
        .no-activities {
          margin: 4rem auto;
          text-align: center;
        }
        .no-results-img {
          width: 40%;
        }
        .missing-results {
          color: var(--d2l-color-ferrite);
          font-size: 19px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .view-activities-back-to-top-container {
          display: flex;
          font-size: 0.7rem;
          justify-content: center;
        }
        .view-activities-header {
          align-items: center;
          background: #ffffff;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          position: relative;
          width: 100%;
          z-index: 1;
        }
        .autocomplete-input {
          height: fit-content;
          max-width: 400px;
          width: 35vw;
        }
        :host([skeleton]) .d2l-heading-2.d2l-skeletize::before {
          bottom: 0;
          top: 0;
        }
        activity-filter {
          width: 100%;
        }

        @media (max-width: 767px) {
          .no-results-img {
            width: 75%;
          }
          .view-activities-header {
            flex-direction: column;
            margin: auto;
            text-align: center;
            width: 100%;
          }
          .d2l-heading-2 {
            margin-bottom: 12px;
            margin-top: 0;
            text-align: center;
          }
          .activity-search {
            display: grid;
            margin-bottom: 12px;
            width: 100%;
          }
          .autocomplete-input {
            max-width: none;
            width: 100%;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.streamOrder = [];
    this.skeleton = true;
    this._isPopulated = false;
    this._filter = new ActivityFilter();
    this._careerFilterItems = [];
    this._visibleCareerStreamId = null;
    this._lastCareerIdSentAsEvent = null;
    this._refreshing = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  get _prefersReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion: reduce)');
  }

  // 'both', 'specialized', or 'common'
  get _careerSkillTypes() {
    return this.session.tenant?.careerExplorer?.settings?.skillsIncluded;
  }

  updated(changedProperties) {
    if (changedProperties.has('_refreshing') && !this._refreshing && this._searchEventReady) {
      this._updatePageTitle();
      this._sendSearchExecutedEvent();
      this._searchEventReady = false;
    }
  }

  async firstUpdated() {
    const skillData = await this.client.getSkills({});
    const providers = await this.client.listTenants('provider');
    this._skills = skillData.skillCounts;
    this._filter = new ActivityFilter({ ...this.session.user.getSetting('filters'), validProviders: providers });
    this.performancePOC = this.session.tenant.hasFeature('performancePOC');
    await this.setupCareerFilter();
    this._updatePageTitle();
    this._allStreams = await this.client.getAllStreams(this.session.user.tenantId);
    await this._refreshActivitiesDashboard();
    this.skeleton = false;
  }

  get _careerStreamAnimationOptions() {
    return {
      keyframeOptions: {
        duration: 333,
        fill: 'both',
        easing: 'ease-in-out',
      },
      properties: ['top', 'opacity'],
      in: [{
        transform: 'translateY(-50%)',
        offset: 0,
      },
      {
        transform: 'translateY(0)',
        offset: 1,
      }],
      out: [{
        transform: 'translateY(0)',
        offset: 0,
      },
      {
        transform: 'translateY(-50%)',
        offset: 1,
      }],
      skipInitial: true,
    };
  }

  animationController = new AnimateController(this, {
    defaultOptions: {
      keyframeOptions: {
        duration: 333,
        fill: 'forwards',
        delay: 333 / 2,
        easing: 'ease-in-out',
      },
      properties: ['top'],
    },
  });

  async setupCareerFilter() {
    // Depending on tenant setting, reset certain career filters if they are not enabled anymore
    if (this.session.tenant?.hasTag('careerExplorer')) {
      // reset in case tenant setting has changed from LOTs to Job Titles or other career item type
      CAREER_ITEM_TYPES.forEach(item => {
        if (item !== this._careerFilterProp) {
          this._filter[item] = [];
        }
      });

      this._careerFilterItems = await this.client.getCareerItems(this._careerFilterProp);
      this._careerFilterItemMap = mapify(this._careerFilterItems);
    } else {
      this._filter.jobs = [];
      this._filter.lots = [];
      this._careerFilterItems = [];
      this._careerFilterItemMap = {};
    }
  }

  get _skeletonActivityListsTemplate() {
    return Array(5).fill(html`<activity-list heading=${this.localize('general.loading')} skeleton></activity-list>`);
  }

  get _activityListsTemplate() {
    const content = repeat(this._streams, stream => stream.id, stream => {
      const { hits, total } = stream.results ?? {};
      const totalNumberOfHits = total?.value ?? 0;
      const activities = hits ? this._getActivitiesFromHits(hits) : null;
      let animateOptions = { ...this.animationController.defaultOptions };
      if (stream.id === 'myList' && totalNumberOfHits > 0) {
        const { myList = [] } = this.session.settings;
        stream.filters = { ...this._filter, id: myList };
        stream.path = 'myList';
      }

      if (stream.type === 'career') {
        // careerSkillTypes and activities required to get 'Top skills' subtitle text
        const updatedStream = new CareerStream({
          ...stream,
          careerSkillTypes: this._careerSkillTypes,
          activities,
          filters: this._filter,
          sourceType: this._careerFilterProp,
        }).streamProperties;

        this._shallowUpdate(stream, updatedStream);

        animateOptions = this._careerStreamAnimationOptions;
      }

      if (!this._refreshing || this._prefersReducedMotion) {
        animateOptions.disabled = true;
      }
      if (this.performancePOC) {
        const streamOrder = this.streamOrder.find(o => o.id === stream.id);
        if (streamOrder?.results === 0) return nothing;
        if (stream.loaded && totalNumberOfHits === 0) return nothing;
      }

      return html`
        <activity-list
          @d2l-wave-activity-carousel-page-change=${this._carouselPageChange(stream)}
          .totalActivitiesInList=${totalNumberOfHits}
          path=${stream.path || ''}
          .heading=${stream.displayName}
          .activities=${activities}
          .subtitle=${stream.subtitle}
          ?performancePOC=${this.performancePOC}
          ?loaded=${stream.loaded}
          ?skeleton=${(this.performancePOC && !stream.loaded) || (!this.performancePOC && activities === null) || this._refreshing}
          remote
          ${animate(animateOptions)}>
        </activity-list>`;
    });

    return html`<div class="activity-list-container">${content}</div>`;
  }

  render() {
    const isPopulated = (this.performancePOC && this.streamOrder.some(o => o.results > 0)) || (!this.performancePOC && this._isPopulated);

    return html`
      <div class="view-activities-header">
        <h1 class="d2l-heading-2 d2l-skeletize">${this.localize('view-activity.title')}</h1>
        <d2l-labs-autocomplete
          id="autoComplete"
          role="search"
          class="activity-search"
          show-on-focus
          .filterFn=${this._autocompleteFilter}
          remote-source
          @d2l-labs-autocomplete-filter-change="${this._autoCompleteFilterChange}"
          @d2l-labs-autocomplete-suggestion-selected=${this._searchChange}>
          <d2l-input-search
            id="search"
            label="${this.localize('view-activities.search.placeholder')}"
            @keydown=${this._stopPropagation}
            @d2l-input-search-searched=${this._searchChange}
            placeholder="${this.localize('view-activities.search.placeholder')}"
            class="autocomplete-input d2l-skeletize"
            slot="input"
            .value=${this._filter.searchCriteria}
            ?skeleton=${this.skeleton}>
          </d2l-input-search>
        </d2l-labs-autocomplete>
        <activity-filter
          id="activity-filter"
          .skills=${this._skills}
          .careerFilterItems=${this._careerFilterItems}
          @filter-changed=${this._filterChange}
          .showActiveFilter=${this.session.tenant.type === 'provider'}
          ?skeleton=${this.skeleton}>
        </activity-filter>
      </div>

      ${this.skeleton ? this._skeletonActivityListsTemplate : isPopulated ? this._activityListsTemplate : this._noResultsTemplate()}
      ${this._backToTopTemplate}
    `;
  }

  scrollToTop() {
    window.scrollTo({ top: 0 });

    // The search bar magnifier button is the previous focused element before the
    // "Skills" dropdown button, and is buried under nested shadow-roots.
    const search = this.shadowRoot.getElementById('search');
    const searchButtonWrapper = search.shadowRoot.querySelector('d2l-button-icon');
    const searchButton = searchButtonWrapper.shadowRoot.querySelector('button');

    // Initially focus on this button element, then unfocus. When pressing tab again,
    // the focused element will be the "Skills" dropdown button.
    searchButton.focus();
    searchButton.blur();
  }

  get _backToTopTemplate() {
    return html`
      <div class="view-activities-back-to-top-container">
        <app-link d2l-link
          class="view-activities-back-to-top-link"
          @click=${this.scrollToTop}
          .ariaLabel="${this.localize('view-activity.backToTop.ariaLabel')}">
          ${this.localize('view-activity.backToTop')}
        </app-link>
      </div>
    `;
  }

  get _careerFilterApplied() {
    return this._filter.hasCareerFilter && this._filter[this._careerFilterProp]?.length > 0;
  }

  get _textSearchApplied() {
    return this._filter?.searchCriteria?.trim().length > 0;
  }

  _autocompleteFilter(value, filter) {
    return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  }

  async _autoCompleteFilterChange(e) {
    const filterValue = e.detail.value;
    const autoComplete = this.shadowRoot.getElementById('autoComplete');
    const activities = await this.client.searchActivities({
      from: 0,
      size: 8,
      filters: { ...this._filter, searchCriteria: filterValue },
    });
    if (activities?.hits) {
      // We have multiple activities with the same name, filter duplicates. We also return more than the required
      // amount just in case we need to filter some out, so we splice it at length 4.
      const uniqueTitles = new Set(activities.hits.map(({ title }) => title));
      const trimmedFilter = filterValue.trim().toLowerCase();
      const uniqueTitlesArray = Array.from(uniqueTitles).sort((current, next) => {
        const currentScore = current.trim().toLowerCase().includes(trimmedFilter) ? 1 : 0;
        const nextScore = next.trim().toLowerCase().includes(trimmedFilter) ? 1 : 0;
        return nextScore - currentScore;
      }).slice(0, 4);

      autoComplete.setSuggestions(uniqueTitlesArray.map(value => ({ value })));
    }
  }

  _getActivitiesFromHits(hits) {
    return hits.map(act => {
      const activity = new Activity(act);
      if (this._careerFilterApplied) {
        const selectedCareerItems = this._filter[this._careerFilterProp];
        const filteredCareerItems = this._careerFilterItems.filter(c => selectedCareerItems.includes(c.id));
        activity.skills = activity.getCareerSkills(filteredCareerItems, this._careerSkillTypes);
      }
      return activity;
    });
  }

  _carouselPageChange(stream) {
    const streamFilters = this.client.prepareFiltersForApiCall(stream.filters);
    const catalogFilters = this.client.prepareFiltersForApiCall(this._filter);
    return async e => {
      const { from, size } = e.detail;
      stream.results = await this.client.searchActivities({
        from: from,
        size: size,
        filters: {
          ...streamFilters,
          ...catalogFilters,
        },
        randomizeOrder: !this._careerFilterApplied,
        category: stream.type === 'category' ? stream.id : stream.category,
        sort: stream.sort,
        property: stream.property,
        excludeCategories: stream.type === 'custom',
      });
      stream.loaded = true;
      this.requestUpdate();
    };
  }

  async _filterChange(e) {
    this._refreshing = true;
    if (e && e.detail.filter) {
      this._filter = e.detail.filter;
    }

    this._filter = new ActivityFilter(this._filter);
    this._filter.searchCriteria = this.shadowRoot.getElementById('search').value.trim();

    const skillData = await this.client.getSkills(this._filter);
    this._skills = skillData.skillCounts;

    // update filter to user session settings
    this.client.setSessionSetting('filters', this._filter);

    // This will need to update this if multiple career item selection becomes supported
    this._previouslyVisibleCareerStreamId = this._visibleCareerStreamId;
    this._visibleCareerStreamId = this.selectedCareerItemData[0] ?? null;
    this._refreshActivitiesDashboard();

    // This will also need to be updated if 'multiple' careers are allowed to be selected
    const newCareerFilterApplied = this.selectedCareerItemData.length > 0
      && this.selectedCareerItemData[0].id !== this._lastCareerIdSentAsEvent;
    if (newCareerFilterApplied) {
      this._logCareerFilterAppliedEvent(this.selectedCareerItemData[0]);
      this._lastCareerIdSentAsEvent = this.selectedCareerItemData[0].id;
    } else if (this.selectedCareerItemData.length === 0) {
      // user hasn't applied the filter, or they removed it
      this._lastCareerIdSentAsEvent = null;
    }
  }

  _logCareerFilterAppliedEvent(careerData) {
    this.client.logEvent({
      eventType: 'careerFilterApplied',
      careerId: careerData.id,
      careerName: careerData.name,
      careerType: this._careerFilterProp === 'jobs' ? 'Job Title' : 'LOT Occupation',
      totalActivitiesInCareerStream: careerData.count,
    });
  }

  // The d2l-labs-autocomplete component's defined behaviour is to have the Home and End
  // keys focus on the first and last options of the dropdown list, respectively.
  // This is undesirable while the input field is in focus. So, this function makes
  // those keys move the cursor to the beginning and end of the typed content of
  _noResultsTemplate() {
    switch (this._filter.populatedType) {
      case 1:
        return html`
          <no-results>
            ${this.localize('view-activity.noResults.prompt.1')}
            <div class="missing-results">${this._filter.searchCriteria}</div>
          </no-results>
        `;
      case 3:
        return html`
          <no-results>
            ${this.localize('view-activity.noResults.prompt.1')}
            <div class="missing-results">${this.localize('view-activity.noResults.prompt.2', { 'searchCriteria': this._filter.searchCriteria })}</div>
          </no-results>
        `;
      default:
        return html`<no-results></no-results>`;
    }
  }

  _getStreamProps(streamData, results = undefined) {
    return {
      displayName: streamData.displayName,
      subtitle: streamData.subtitle,
      id: streamData.id,
      path: streamData.path,
      category: streamData.category,
      type: streamData.type,
      sort: streamData.sort,
      property: streamData.property,
      careerData: streamData.careerData,
      results: results,
      filters: streamData.filters,
    };
  }

  async _refreshActivitiesDashboard() {
    const careerStreamData = this.selectedCareerItemData;
    const careerStreams = [];
    careerStreamData.forEach(careerData => {
      careerStreams.push(new CareerStream({
        careerData,
        filters: this._filter,
        sourceType: this._careerFilterProp,
      }).streamProperties);
    });

    const { myList = [] } = this.session.settings;
    const myListStream = myList.length > 0
      ? [{
        displayName: this.localize('activity.category.myList'),
        filters: { ...this._filter, id: myList },
        aggregateProperty: {
          filter: {
            ids: {
              values: myList,
            },
          },
        },
        id: 'myList',
        type: 'myList',
      }]
      : [];

    const customStreams = this._allStreams?.customStreams || [];
    const allStreams = customStreams.concat(
      careerStreams,
      myListStream,
      this._allStreams?.dynamicStreams,
      this._allStreams?.staticStreams
    );

    let streams = [];

    // For a dynamic stream to be visible it needs to have at least 4 courses, unless there is any type of filter applied
    // For all other streams and filter conditions we need at least 1 course.
    const getMinHits = ({ type }) => (type === 'dynamic' && this._filter.populatedType === 0 ? 4 : 1);

    if (this.performancePOC) {
      const streamsMap = mapify(allStreams);
      this.streamOrder = await this.client.getStreamOrder({
        filters: this._filter,
        defaultStreamIds: allStreams.filter(stream => stream.type === 'category').map(stream => stream.id).join(','),
        dynamicFilters: JSON.stringify(allStreams.filter(stream => stream.type !== 'category').map(stream => {
          return {
            key: stream.id,
            type: stream.type,
            aggregateProperty: stream.aggregateProperty,
          };
        })),
      });
      // sort categories by max_score
      this.streamOrder.sort((a, b) => {
        if (a.type === 'category' && b.type === 'category') return b.max_score - a.max_score;
        return 0;
      });

      for (let index = 0; index < this.streamOrder.length; index++) {
        const streamId = this.streamOrder[index].id;
        const stream = streamsMap[streamId];
        if (!stream || this.streamOrder[index].results < getMinHits(stream)) continue;
        streams.push(this._getStreamProps(stream, this.streamOrder[index]));
      }
    } else {
      const promises = [];

      const getSize = (stream, index) => {
        if (stream.type === 'career') return 70; // Fetching 70 activities to extract top 3 unique skills

        // We only need "total.value" and "max_score" properties for the initial query of streams lower in the viewport
        // Fetch 4 activities each for only the top 4 listed streams or when text search is applied.
        return index < 4 || this._textSearchApplied ? 4 : 0;
      };

      for (let index = 0; index < allStreams.length; index++) {
        const stream = allStreams[index];
        const streamFilters = this.client.prepareFiltersForApiCall(stream.filters);
        const catalogFilters = this.client.prepareFiltersForApiCall(this._filter);
        if (stream.type !== 'custom' || !ActivitiesHelper.streamFiltersConflict(streamFilters, catalogFilters)) {
          promises.push(this.client.searchActivities({
            from: 0,
            size: getSize(stream, index),
            filters: {
              ...streamFilters,
              ...catalogFilters,
            },
            randomizeOrder: !this._careerFilterApplied,
            category: stream.type === 'category' ? stream.id : stream.category,
            sort: stream.sort,
            property: stream.property,
            excludeCategories: stream.type === 'custom',
          }).then(results => {
            return this._getStreamProps(stream, results);
          }));
        }
      }

      streams = (await Promise.allSettled(promises))
        .filter(stream => stream.status === 'fulfilled' && stream.value.results?.total?.value >= getMinHits(stream.value))
        .map(({ value }) => value);

      this._isPopulated = streams.some(stream => stream.results.total.value > 0);
    }

    // Set _streams to reload the page with skeletons until stream data is loaded
    // It's the same stream info but without results as they haven't been fetched yet
    if (this._streams) {
      const newCareerStreamAdded = this._visibleCareerStreamId !== null
        && this._previouslyVisibleCareerStreamId !== this._visibleCareerStreamId;
      const newCareerStreams = newCareerStreamAdded ? [careerStreams[0]] : [];
      this._streams = newCareerStreams.concat(this._streams).map(stream => {
        return this._getStreamProps(stream);
      });
    }

    const getSortValue = (current, next) => {
      const aScore = current || -1;
      const bScore = next || -1;
      return bScore - aScore;
    };

    if (this._textSearchApplied) {
      streams.sort((current, next) => {
        if (current.type === 'career' && next.type === 'career') return 0;
        if (current.type === 'career') return -1;
        if (next.type === 'career') return 1;
        if (!current.results?.max_score) return 1;
        if (!next.results?.max_score) return -1;
        return getSortValue(current.results?.max_score, next.results?.max_score);
      });
    } else if (careerStreams.length) {
      streams.sort((current, next) => {
        if (current.type === 'category' && next.type === 'category') {
          if (!current.results?.total?.value) return 1;
          if (!next.results?.total?.value) return -1;
          return getSortValue(current.results?.total?.value, next.results?.total?.value);
        }
        return 0;
      });
    }
    this._streams = streams;
    this._refreshing = false;
  }

  _shallowUpdate(stream, newStream) {
    for (const key in newStream) {
      stream[key] = newStream[key];
    }
  }

  _updatePageTitle() {
    const _localizedSearchTitle = () => {
      return this.localize('view-activities.documentTitle.search', {
        searchCriteria: this._filter.searchCriteria,
      });
    };

    const documentTitle = this._textSearchApplied
      ? _localizedSearchTitle() // include search in title
      : this.localize('view-activities.documentTitle'); // search empty
    this.client.setDocumentTitle(documentTitle);
  }

  async _searchChange(e) {
    await this._filterChange(e);
    this._searchEventReady = true;
  }

  _sendSearchExecutedEvent() {
    const numberOfResults = this._streams.reduce((memo, stream) => {
      return memo + (stream.results?.total?.value ?? 0);
    }, 0);
    this.client.logEvent({
      eventType: 'searchExecuted',
      numberOfResults,
      keywords: this._filter.searchCriteria,
    });
  }

  get selectedCareerItemData() {
    // Get array of career data items, filter out items that are no longer in this._careerFilterItemMap[id]
    const careerItemData = this._filter[this._careerFilterProp]?.map(id => this._careerFilterItemMap[id]).filter(x => x);

    if (!careerItemData?.length && this._filter[this._careerFilterProp]) {
      this._filter[this._careerFilterProp] = [];
    }
    return careerItemData || [];
  }

  get _careerFilterProp() {
    return this.session.tenant?.careerExplorer?.settings?.showLots
      ? 'lots'
      : 'jobs';
  }

  _stopPropagation(e) {
    const [HOME, END] = [36, 35];
    const { keyCode } = e;
    if (keyCode === HOME || keyCode === END) {
      e.stopPropagation();
    }
  }
}

window.customElements.define('view-activities-dashboard', ViewActivitiesDashboard);
